//  TODO:  Split this to another module.

/* eslint-disable import/first */
//  Import Plugins
//  TODO:  DO this declartively or register somethow?
import { EventEmitter } from 'events';
import * as React from 'react';
import { ActivityIndicator, Text, View } from 'react-native';

//  TODO:  Inject even THIS with a Plugin? Hmm.. if that's possible, maybe we don't even need this though, because that implies there's a way to inject componetns  HM!  Let's just keep pushing for now and hopefully we'll see patterns pop out and stuff hm!

//  EXPLANATION:  This is the temporary way I support UI injections with Halia.  In the future, I'd like to have a React Halia Module that we INNJECT into to build the app!? Hm!  BUT because we already have the app, we can do this!? HM!
//                So we can use this to push component from Halia.. like a back-door.  BUT maybe we ALSO expose some other APIs like the Alert API? Hm!

//  EXPLANATION:  The idea is, we have "exported" things from INSIDE the component tree that we can use in Halia plugins... or anywhere.

//  CONCERN:  I'm jut not a fan of this model and it pokes through the componet tree / Halia? HMM I THINK that CAN be ok!  But.. is it necessary to do this?? It MAYBE come with dependnecy resolutionn prroblems? HM!

// export const components: (React.FunctionComponent | React.ComponentClass)[] = [];

//  TODO:  Standardize the registers here.  ALSO consider ExtensibleClass, ExtensibleComponent, and ExtensibleFunction things!  HM!  BUT CONSIDER building those with my own entity / association, "tag" based framewok and then constructing hm!

//  TODO:  Separrate this into ANOTHER "Plugin" and couple HM!
export interface HaliaReactExports {
  navigation?: any;
  showAlert?: (title, icon, description, onContinue) => void;
  registerHOC: any;
  registerOnMount: any;
}

//  TODO:  This should be part of the "ReactApp" plugin hm!
//  NOTE:  We MAY still want to COMBINE this with thing slike CSS-like system and all that MAYBEEEEE hte dev encodings are ONE grraph to be interrpreted and shit and the runtime is another hm!  MAYBE we can KEEp re-using the SAME shit for building encodingsa and renderrings and allll that hm!!!  The idea is, a generic, strucutre like a graph that we can fuck with reliably and shti hm!!!!

const DefaultRoot = () => (
  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Text style={{ fontSize: 25 }}>Halia-React</Text>
    <View style={{ height: 10 }} />
    <Text style={{ fontSize: 40 }}>🔌 ⚛️</Text>
    <View style={{ height: 10 }} />
    <Text style={{}}>Add Plugins to build your React Native App!</Text>
    {/* TODO:  Add quick help links and stuff to get users started building pluggable apps hm! */}
  </View>
);

//  NOTE:  We want to do TWO things... Build a haliastack, and then mount it.  THIS component is for building React Components hm!

//  EXPLANATION:  To support sibling injection, we need a parent component.  We CAN perhaps have a "blank" one which just lists them.. hmm. BUT... when we wrap with HOC, te oint is to make sure ALL future ones have that context registered in thei stack? hmmm...

export const hocRegisters: { [id: string]: { onMount: any[], hocs: any[], childComponents: any[] } } = {};

//  FEELING:  REALLY feels like we're building the SAME pattern again!!!  INSTEAD, make a GENERIC node that shit INCLUDING dev shit can be asociated with!? HM!  THIS way we can do this for ALL these diffeentiations, functions / components / classes / ... everything, etc hm!
export const registerHOCRegister = (id: string) => {
  hocRegisters[id] = {
    onMount: [],
    hocs: [],
    childComponents: []
  };
};

//  Register for HOCs
//  NOTE:  We wrap in FIFO order to match the Plugin dependency order is met.
//  CONSIDER:  We can give this a NAME for debugging purposes.. MAYBEEEE THOUGH, MOST things CAN have names and it's just "associated" encodings ... KINDA like "metadata" hmm... but still handled by a system hm!  Maybeee!  Again.. the BAG / staking system / network??? HM!

//  THOUGHT:  Instad of using this pre-registration flow, it would be nice to have it part of the same thing? hmm... AGAIN, I think we're moving to the BAG system hmm!
const reactAppEmitter = new EventEmitter();

/**
 *  Each registered child component will be appended to the
 *  CONSIDER:  Similar to HOC composition, MIGHT we want to be able to NEST children?  Basically.. we want a simple model that lets us do wtf we want WITHOUT coupling where we don't need it!
 *  TODO-IMPORTANT:  I think there IS a simplerr model.. I have a lot of dissonacne and tension because this seems sooo coupled and a lot of unnecessary things.. I like the idea of using an "If" cpntext and REMOVING conditioanls hm!!!
 */
export const registerChildComponent = (id: string, childComponent: any) => {
  const hocRegister = hocRegisters[id];
  if (!hocRegister) { throw new Error(`registerChildComponent:  No HOC Register has been registered for ID: '${id}'`); }
  hocRegister.childComponents.push(childComponent);
  //  TODO:  There's no reason for this to call ALL!  Instead, we SHOULD be able to tie ourselves to the state that the "registerHOC" was called WITH a paticula ID.  We CAN do that manually by writing code, but it just doesn't solve the GENERIC problem of CONTEXt that we all just KEEEEPP solving ugh! HM!  Instead, build te context system!!!
  reactAppEmitter.emit("child-component-registered", { id });
};

//  TODO:  Support a NAME for the HOC.  THIS is a perfect example!  We want to ADD a property here.. BUT doing that means changing ALL the users to support that thing... first of all.. we SHOLD be able to change it and then see.. hmm.. ok we can already do that.. hmm.. but maybe we want it GUIDED ugh.. so the user can SEE what's going on hmm...  WOULD like a way to remove an HOC.. FOR NOW.. let's just do it by reference hm!
export const registerHOC = (id: string, hoc: any) => {

  const hocRegister = hocRegisters[id];
  if (!hocRegister) { throw new Error(`registerHOC:  No HOC Register has been registered for ID: '${id}'`); }
  hocRegister.hocs.push(hoc);

  //  TODO:  There's no reason for this to call ALL!  Instead, we SHOULD be able to tie ourselves to the state that the "registerHOC" was called WITH a paticula ID.  We CAN do that manually by writing code, but it just doesn't solve the GENERIC problem of CONTEXt that we all just KEEEEPP solving ugh! HM!  Instead, build te context system!!!
  reactAppEmitter.emit("hoc-changed", { id });
};

export const removeHOC = (id: string, hoc: any) => {
  const hocRegister = hocRegisters[id];
  if (!hocRegister) { throw new Error(`registerHOC:  No HOC Register has been registered for ID: '${id}'`); }
  const existingHOCIndex = hocRegister.hocs.findIndex(_hoc => _hoc === hoc);
  hocRegister.hocs.splice(existingHOCIndex, 1);
  //  TODO:  There's no reason for this to call ALL!  Instead, we SHOULD be able to tie ourselves to the state that the "registerHOC" was called WITH a paticula ID.  We CAN do that manually by writing code, but it just doesn't solve the GENERIC problem of CONTEXt that we all just KEEEEPP solving ugh! HM!  Instead, build te context system!!!
  reactAppEmitter.emit("hoc-changed", { id });
};

export const registerOnMount = (id: string, func: any) => {
  const hocRegister = hocRegisters[id];
  if (!hocRegister) { throw new Error(`registerOnMount:  No HOC Register has been registered for ID: '${id}'`); }
  hocRegister.onMount.push(func);
  reactAppEmitter.emit("on-mount-registered", { id });
};

//  CONSIDER:  We CAN build a system to register a SIBLING as well.  Now just a parent HOC... MAYBE also inject Children? Hmm... WHy have this be so specific to React though? HM!  MAYBE work on formalizing my quetions and ask on Reddit / Dev.to etc hm!

export const HOCRegister = ({ id, children: inlineChildren }: { id: string, children?: any }) => {

  const hocRegister = hocRegisters[id];

  const [hocs, setHOCs] = React.useState([...hocRegister.hocs]);
  const [childrenComponents, setChildrenComponents] = React.useState([...hocRegister.childComponents]);

  //  Tie the State to the Registers
  //  TODO:  Do this on load??
  //  CONSIDER:  This context shouldn't have to KNOW it's even POSSiBLE to have other ids!! HM!  this is where context assignment OVERR ordered, evaluated conditionals can be very helpfull hm!!

  reactAppEmitter.addListener("hoc-changed", ({ id: registeredId }) => {
    if (id === registeredId) {
      setHOCs([...hocRegister.hocs]);
    }
  });

  reactAppEmitter.addListener("child-component-registered", ({ id: registeredId }) => {
    if (id === registeredId) {
      setChildrenComponents([...hocRegister.childComponents]);
    }
  });

  //  REALIZATION (rresurfaced):  It would be INSANELY helpful o be able to "throw" from an inner "function" and have that throw the outer caller too.. AS IF they were ONE function.  THis is a way to compose a function with others WITHOUT changing the JS stack / closure?? HM!  Interresting!  In he BAG, we could be expliclt about the colusre / context??? hm!
  if (!id) { throw new Error(`HOCRegister:  No ID was provided.`); }
  if (!hocRegister) { throw new Error(`HOCRegister:  No HOC Register has been registered for ID: '${id}'.`); }

  //  TODO:  Build a "Loader" thing which can be inject as a higher level thing hm!
  //  EXPLANTION:  MAYBE make a "LoadingGuard" which will not show children until loading completes? Hmm.. HOW will we know when loading is done and update?   We can pass? hm... maybe.  Ah!  I THINK the idea is we can INJECT using a COUPLING system hm!  NICEE!!!  THIS way, if we have a LoadingGuard thing, we can then have a "FontManager" and a "FontManager / LoadingGuard" coupler which will do the fucking copulig hm!! I THINK I loveeee that idea!!  THEN it keeps them separate BUT also categorizes them for EASY view-based manipulation and shitt!!! HM!!!!MMMM!!!!!
  //  NOTE:  This DOM-like model is NOT specific to web or React, and I thikn it's basically a GRAPH.. it's a way to program without ORDERED STATEMENTS and shit where things can be in a graph with multiple views, normalizeddd hm!
  //  CONCERN:  What if I'm not "smart" enough for this stuff... the point is, I believe MOST people can be... it's just a matter of pushing and belief training, and working on yourself.. MOST people don't do that shit!  If you REALLY want to be "smarter", push yourself to do thigns that you could't originally do, and work on fundamentalizing knowledge to keep it COMPACT!!!  The idea of representing ANYTHING and EVERYTHING as an encoding / interpreter, in the domain of semiotics REALLY helps, as does observing MOST (any?) encoding can be a graph.. fudamntal ontology perhap? Hmm.. WHAT abotu CONTINOUS? HMM!  MAYBE not that category?
  //  NOTE:  "Class" is overloaded in programming.. it's just yeahh hm!  LOL the DISTINCTION between CS and Progamming and shit is ALL semantic! HM!  It's fucking arbitrary up to HUMANS to decide AHHH!!! SO coooll!! Sis stresses  me a bit... Love herr, but she yeah ... hmmm... MAYBE her pre-ocupation with other stuff and NOT feeling particulaly stress about this, plus underdog advantage hm!
  const [loading, setLoading] = React.useState(true);

  const handleOnLoad = async () => {

    //  TODO:  SHould only load fonts ONCE!  Move out of this! hmm....

    //  TODO:  Should happen in the HaliaReact PLUGIN Framework! Hm!
    for (let i = 0; i < hocRegister.onMount.length; i++) {
      const func = hocRegister.onMount[i];
      await func();
    }
    setLoading(false);
  };

  React.useEffect(() => {
    handleOnLoad().catch(err => { alert("An error occurred while loading the register: " + err); });
  }, []);

  //  Grab Navigation
  //  CONCERN:  I BELIEVE this is dangerous.  The framework MAY internally track how it's used and stuff.  SHOLD be find as long as it's assumed that it's being used from THIS component.
  //  CONSIDER:  Inject this from a Plugin?
  // const navigation = useNavigation();
  // HaliaReactExports.navigation = navigation;

  //  Function to Show Alert
  //  CONSIDER:  Inject this from a Plugin?
  // HaliaReactExports.showAlert = (title, icon, description, onContinue) => {
  //   navigation.navigate(WrapperRoute.Root, { screen: RootRoute.AlertBox, params: { title, icon, description, onContinue } });
  // }

  //  TODO:  Solve the problem of injecting new HOCs (dynamic functions) after mount.
  //  NOTE:  HOCs are added from the root plugins then down to the plugin users.

  const buildTree = (_hocs: any[]) => {

    //  CASE:  No HOCs Remaining
    if (!_hocs.length) {
      //  CONCERN:  This feels SOOO funky.. I don't like it much ugh.. let's go fo now.. like.. seems like it's pretty smila to what we already have.. too specialized?? Hmm... not fundamntal enotuhg??? HM! Idk mannn hm!
      const injectedChildren = childrenComponents.map(CC => <CC />);
      // console.log(id + "::Injected Children: " + injectedChildren.length);
      // <ScrollView style={{ flex: 1, display: 'flex' }} contentContainerStyle={{ flex: 1 }}>
      //     </ScrollView>
      return (
        <>
          {injectedChildren}
          {/* REALIZATION:  In the NEW model there will PERHAPS be NO difference between "inline" and "injected", they will be one in the same hm! */}
          {inlineChildren}
          {!inlineChildren && !injectedChildren && <DefaultRoot />}
        </>
      );
    }

    //  CASE:  HOCs Remaining
    const HOC = _hocs[0];
    const _children = buildTree(_hocs.slice(1));
    // console.log("Added HOC: " + HOC);
    return <HOC>{_children}</HOC>;
  };

  //  NOTE:  We REVERSE the HOCs to ensure LIFO ordering.  This way, whenever we use "registerHOC" we can be sure it will be higher than ALL previously registered.  E.g. Halia Component <- Layout <- App.  We inject the App into the layout, but the Layout Plugin registers an HOC.  This way, when we're in the "install" function of App and we register an HOC, it will be ABOVE the Layout HOC.  This means, everything we inject into Layout will have access to the App Context.
  //  TODO:  Memoize!  I tried this, but some registrations were not showing.  Possible race condition?
  // const hocElements = React.useMemo(() => {
  //   return buildTree([...hocs].reverse());
  // }, [hocs]);

  const hocElements = buildTree([...hocs]);

  //  TODO:  Should NOT be hard-coded and should be INJECTED!!! hM!  See above!! LOVE that new model damn!  It really helps NORMALIZE the encoding and make it possible to generate MULTIPLE views of the same!! MMMMMMM!!! SCRUMPTIOUS!! LOL!

  // TODO:  Only show the thing being loaded in debug mode.  SHOULD always load immediately!

  if (!loading) {
    return hocElements;
  } else {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        {/* <Text>Loading the '{id}' HOC</Text> */}
        {/* TODO:  Custom Loader */}
        <ActivityIndicator size={ 50 } color={ '#f53183' } />
      </View>
    );
  }

};
